import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { InfoText, ErrorMessage } from '../src/index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alerts"
    }}>{`Alerts`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "info-text"
    }}>{`Info text`}</h2>
    <Playground __position={1} __code={'<InfoText state=\"success\" text=\"Success!\" />\n<div style={{ height: \'16px\', width: \'100%\' }} />\n<InfoText state=\"error\" text=\"Error\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      InfoText,
      ErrorMessage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InfoText state='success' text='Success!' mdxType="InfoText" />
  <div style={{
        height: '16px',
        width: '100%'
      }} />
  <InfoText state='error' text='Error' mdxType="InfoText" />
    </Playground>
    <h2 {...{
      "id": "error-message"
    }}>{`Error message`}</h2>
    <Playground __position={2} __code={'<ErrorMessage />\n<br />\n<ErrorMessage text=\"Error happened. This is a custom message\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      InfoText,
      ErrorMessage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ErrorMessage mdxType="ErrorMessage" />
    <br />
    <ErrorMessage text="Error happened. This is a custom message" mdxType="ErrorMessage" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={InfoText} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      